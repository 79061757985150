.Form{
	
	width:90%;
	position:relative;

		max-width:400px;
	
	margin:0;
	margin-right:auto;
	border:1px solid black;
	.Form-field input{

		background:white;
		border:none;
		padding:12px;
		@extend %u-text--small;
		line-height:12px;
		
		display:block;
		width:100%;
		color:$base-color;
		border-radius:0px;
		position:relative;

	}

	.Form-field-caption{
			text-transform:uppercase;
			@include font-size(9px);
			padding-top:3px;
		}

	.Form-field{
		padding:0;
		text-align:left;
			
	}
	.Form-submit{
		padding-left:10px;
		@extend %u-text--small;
		padding-top:0!important;

		.Link{
			border-radius:0px;
			padding:12px;
		
			line-height:100%;
			position:absolute;
			bottom:0;
			right:0;
			width:100px;
			min-width:0;
			margin:0;
			line-height:12px;
			border-top-left-radius:0;
			border-bottom-left-radius:0;
			color:white;
			background:black;
		}
	}

	
	.Form-field-title{
		padding-bottom:10px;
		color:white;
		text-transform:uppercase;
		
			text-align:center;
		
	}
	.Form-field--small{
		display:inline-block;
		width:50%;
		vertical-align:top;

	}

	.Form-field--hon{
		display:none;

	}

	.Form-submit{
		padding-top:20px;
		text-align:center;
		color:$base-color;
	}

	&.Form--icon{
		border:none;
		@include responsive('margin-left',$base-padding);
		@include responsive('margin-right',$base-padding);
		
		width:auto;
		max-width:none;
		position:relative;
		
		.Site-search &{
			margin-left:0;
		}
		

		.Form-field{
			border:none;
			input{
				background:transparent;
				border:none;
				@extend %u-text--body;
				padding: 20px 0;
				.Site-search &{
					
					&::placeholder{
						opacity:0.5;
						transition:opacity 0.4s ease;
					}

					&:focus::placeholder{
						//opacity: 0.2;
					}
				}
				letter-spacing:0;
				padding-left:0;

			}
		}
		

		.Form-submit{
			text-align:center;
			position:absolute;
			top:0;
			right:0;
			
			height:100%;
			
			.Link{
				bottom:50%;
				
				transform: translateY(50%);
				color:black;
				background:transparent;
				width:40px;
				
			}

			.Site-search &{
				opacity:0.5;
			}
		}

	}

	&.Form--newsletter{

		.Form-field input,
		.Form-submit .Link{
			height:36px;
		}

	}

}