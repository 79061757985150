@keyframes spin {
  from{
      transform: rotate(0deg);
  }
  to{
     transform: rotate(360deg);
  }
}

@keyframes pulse {

  0%   { opacity: 0;}
    
    50%  {opacity:1}
    
    100% {opacity: 0;}
}