/**
 * Settings
 *
 * Core defaults
 */

/**
 * Breakpoints
 *
 * Hold our breakpoint aliases and conditions in a list. These can be invoked
 * later on via the `respond-to()` mixin found in _mixins.scss.
 */

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "mobile-landscape" "(max-width: 745px) and (orientation: landscape)" "tablet"
    "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "fixed-width" "(min-width: 1400px)",
  "wide" "(min-width: 1500px)",
  "xl" "(min-width: 1700px)",
  "shallow" "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)"
);

$base-border: 1px;

/**
 * Base Grid & Spacing values
 */

$base-vw: 1024;
$base-vh: 768;
$base-padding: (
  "*": 20px,
  "mobile": 15px,
);
$base-grid-gutter: $base-padding;
$base-mobile-spacing: 15px;
$base-max-width: "none";
$base-max-text-width: 700px;
$base-max-dialog-width: 700px;

/**
 * Base Type Settings
 */

$base-font-size: 19px;
$base-line-height: 24px;
$base-letter-spacing: 0;

$base-font-family: "Founders Grotesk", Arial, sans-serif;
$base-font-family-bold: "Founders Grotesk", Arial, sans-serif;
$base-font-family-alt: "Founders Grotesk", Arial, sans-serif;
$base-text-align: left;

$base-text-families: (
  "sans": (
    "weights": (
      "regular": '"Helvetica", Arial, sans-serif',
      "bold": '"Helvetica", Arial, sans-serif',
    ),
    "sizes": (
      "small": $base-font-size * 0.75,
      "medium": $base-font-size,
      "large": $base-font-size * 1.5,
    ),
  ),
  "mono": (
    "weights": (
      "regular": monospace,
    ),
    "sizes": (
      "medium": $base-font-size,
      "small": $base-font-size * 0.75,
    ),
  ),
);

$base-text-applications: ();

$base-text-weights: (
  "*": "20px",
  "mobile": "10px",
);
$base-text-sizes: (
  "*": "20px",
  "mobile": "10px",
);

/**
 * Base Colours
 */

// Blue palette
$pale-blue: #d4e3e6;
$pale-blue-contrast: #074d65;
$pale-blue-accent: #d6ab4d;

// Green palette
$pale-green: #d6e1cb;
$pale-green-contrast: #14613e;
$pale-green-accent: #df5043;

// Pink palette
$pale-pink: #efcdcd;
$pale-pink-contrast: #8e3450;
$pale-pink-accent: #374c98;

// Purple palette
$pale-purple: #d6d2e9;
$pale-purple-contrast: #6e5167;
$pale-purple-accent: #609387;

// Brown palette
$pale-brown: #d8cfc9;
$pale-brown-contrast: #7a624f;
$pale-brown-accent: #d33449;

$grey: #bbbbbb;
$loadingBg: white;
$base-color: #000;
$base-background: #fff;
$base-link-color: #000;
$base-link-opacity-hover: 1;
$base-active-color: #000;

$base-overlay-background: #000;
$base-overlay-transparency: 0.85;

$base-ui-color: #333;
$base-ui-color-alt: #000;

$base-placeholder-color: #787878;
$base-placeholder-opacity: 0.3;

$base-selection-background: rgba(0, 0, 0, 1);
$base-selection-color: #fff;

$base-image-color: #eee;
$base-image-color-loaded: #eee;
$base-image-fade: 2s;
$base-image-delay: 0.25s;
$base-image-ease: ease;

/**
 * Base Icons
 */

//$base-icons : "cross" "arrow-right" "arrow-left" "arrow-up" "arrow-down" "play" "search" ;
$base-icons: "arrow-right" "arrow-left";
$base-icon-extension: "svg";

$base-icon-size: 24px;

$base-icon-glyphs: (
  "down": "\e900",
  "left": "\e90f",
  "right": "\e910",
  "search": "\e90e",
  "phone": "\e901",
  "arrow-left": "\e907",
  "arrow-right": "\e908",
  "menu": "\e909",
  "play": "\e90a",
  "plus": "\e90b",
  "cross": "\e90c",
  "email": "\e90d",
  "map": "\e902",
  "pinterest": "\e903",
  "twitter": "\e904",
  "facebook": "\e905",
  "instagram": "\e906",
  "guide": "\e911",
);

/**
 * Base Cursors
 */

$base-cursor-prev: pointer;
$base-cursor-next: pointer;
$base-cursor-play: pointer;
$base-cursor-pause: pointer;

/**
 * Base Sections (Sections being regions that allow you to control core styling)
 * The keys in this array will be used as modifiers (.Section--{key}) with the config settings provided
 * At a config level Sections can define:
  - background color
  - foreground color,
  - heading typeface,
  - body typeface
  - padding for the .Section-body
  - max width for the .Section-body
  - colour for images
  - colour links
  - colour for active items
 */

$base-sections: (
  "*": (
    "transition": all 0.4s ease,
    "background": transparent,
    "color": $base-color,
    "heading-font-family": $base-font-family,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-image-color,
    "link-color": $base-link-color,
    "active-color": $base-active-color,
  ),
  "modifier": (
    "background": $base-color,
    "color": $base-background,
    "heading-font-family": $base-font-family-alt,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-background,
    "link-color": $base-ui-color,
    "active-color": $base-active-color,
    "when-inside": (
      ".Site--modified": (
        "background": $base-color,
        "color": $base-background,
      ),
    ),
  ),
);

/**
 * Top level hooks
 */

$base-touch-class: ".Site--touch";
