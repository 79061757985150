.Dropdown{

	
	position:relative;
	cursor:pointer;
	max-width:300px;
	
	*{
		@extend %u-text--body;
	}
}
.Dropdown-arrow{
	position:absolute;
	top:0;
	right:0;
	border-left:$base-border solid black;
	height:100%;
	width:40px;
	.Icon{
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		width:20px;
		height:18px;
		font-size:14px!important;
		line-height:18px!important;
		&:before{
			font-size:14px!important;
			line-height:18px!important;
			text-align:center;
		}
	}
}
.Dropdown-head{
	padding:10px;
	border:$base-border solid black;
}
.Dropdown-body{
	position:absolute;
	top:100%;
	left:0;
	width:100%;
	border:$base-border solid black;
	display:none;
	margin-top:-1px;
}

.Dropdown.is-active{
	.Dropdown-body{
		display:block;
	}
}

.Dropdown-body a{
	display:block;
	padding:5px 10px;

	&:hover{
		background:#EEE;
	}

	&.is-active{
		pointer-events:none;
		opacity:0.5;
	}
}