$layout-cols:18;
$layout-rows:18;


.Layout{
  position:relative;
  //height:2000px; // Reset with JS
  height:auto;
  box-sizing: content-box;
  z-index:0;
}
.Layout-item{
	@include respond-to('tablet-up'){
	position:absolute;
  	top:0;
  	left:0;
    z-index:1;
  }
  
}


.Layout-caption{
	
	padding: 10px 0;
  max-width:300px;
	
	@include respond-to('mobile'){
	   padding: 10px 15px;
	 }
	
}

.Layout-shadow{

    box-shadow:4px 4px 10px rgba(0,0,0,0.15);

}


@for $i from 1 through $layout-cols{
  
  [data-layout-colspan="#{$i}"]{
    	@include respond-to('tablet-up'){
      width:($i/$layout-cols) * 100%;
      }
    
  }
  
  [data-layout-offset="#{$i}"]{
    	@include respond-to('tablet-up'){
      left:($i/$layout-cols) * 100%;
      }
    
  }
  
}

[data-layout-top="middle"]{
@include respond-to('tablet-up'){
  top:50%;
  transform:translateY(-50%);
  }
}

[data-layout-top="bottom"]{
	@include respond-to('tablet-up'){
  bottom:0%;
  top:auto;
  }
}

[data-layout-mobile-size="inset"]{
  @include respond-to('mobile'){
  width:65%;
  margin:17.5% auto;
  }
}


@for $i from 1 through $layout-rows{
  
  [data-layout-top="#{$i}"]{
    	@include respond-to('tablet-up'){
			top:($i/$layout-rows) * 100vw;
      }
    
  }
  
  [data-layout-spaces="#{$i}"]{
    @include respond-to('tablet-up'){
      margin-bottom:($i/$layout-rows) * 100vw;
      }
    
  }
  
  [data-layout-padding-bottom="#{$i}"]{
    @include respond-to('tablet-up'){
      padding-bottom:($i/$layout-rows) * 100vw;
      }
    
  }
  
  
}


