%u-layout--toolbar{

	@include responsive('padding-left',$base-padding);
	@include responsive('padding-right',$base-padding);

 	height:55px;
 	line-height:55px;

 	@include respond-to('wide'){
 		height:60px;
 		line-height:60px;
 	}



 	ul,li{
 		line-height:50px;
 		@include respond-to('wide'){
 			line-height:60px;
 		}
 	}

 	.Icon{
 		
 		//font-size:30px!important;
 		//line-height:115%;
 		
 	}

 	input{
 		//height:60px;

 	}

}

%u-layout--shadowed{
	box-shadow:0 $base-border 0 0 rgba(0,0,0,0.15);
}