.Item,
a.Item {
  display: block;
}

.Item {
  position: relative;
  padding: 20px;

  @include respond-to("desktop") {
    padding: 1vw;
  }

  .Item-date {
    color: rgba(0, 0, 0, 0.5);
  }
  .Item-head {
    .Content * {
      @extend %u-text--body;
    }
    .Content h1 {
      font-weight: bold;
    }
    @include responsive("margin-bottom", $base-padding);
  }

  .Item-media {
    @include responsive("margin-bottom", $base-padding);
  }

  .Item-foot {
    @include responsive("margin-top", $base-padding);
    @extend %u-text--smallCaps;
  }

  @include respond-to("mobile") {
    padding: 10px;
  }

  &.Item--noImage {
    .Item-head {
      min-height: 18vh;
      .Content {
        h1,
        h2 {
          @extend %u-text--intro;
          font-weight: normal;
        }
      }
    }
  }
}

.Item--basic {
  padding: 0 !important;
}

.Item--listing {
  padding: 0;
  .Content {
    padding-top: 0;
    padding-left: 0;
  }
  border-bottom: $base-border solid black;
  transition: background 0.4s ease;
  .Item-content {
    @include responsive("padding", $base-padding);
    display: inline-block;
    width: 60%;
    vertical-align: top;
  }

  .Item-media {
    display: inline-block;
    width: 40%;
    vertical-align: top;
    margin: 0;
    position: relative;
    border-left: $base-border solid black;

    a {
      display: block;
      padding-top: 80%;
      min-height: 150px;
      @include respond-to("tablet-up") {
        min-height: 165px;
      }
      @include respond-to("desktop") {
        min-height: 185px;
      }
      position: relative;
    }
  }

  &.is-active {
    background: rgba(255, 255, 255, 0.9);
  }

  .Image {
    background: rgba(0, 0, 0, 0.15);
  }
}

.Item--gallery {
  padding: 0;
  .Item-media {
    margin: 0;
  }
  .Item-body {
    @include responsive("padding", $base-padding);
    padding-top: 10px;
    padding-bottom: 10px;
    @include respond-to("mobile") {
      padding-top: 8px;
      padding-bottom: 0;
    }
    height: 40px;
    line-height: 20px;
    border-top: $base-border solid black;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 1);
  }

  &.Item--fit {
    .Image-media {
      background-position: center;
      background-size: 94%;
      @media screen and (max-width: 820px) {
        background-size: contain;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .Image-media {
      background-color: #000;
      background-position: center center;
      background-size: contain;
    }
  }
}

.Item--accordion {
  padding: 0;
  &:last-child {
    border-bottom: 1px solid;
  }

  & > .Item-body {
    padding: 0;

    border-top: 1px solid;
  }

  & > .Item-head {
    @extend %u-text--intro;
    border-top: 1px solid;
    margin: 0;
    cursor: pointer;
    margin-top: -1px;
    position: relative;

    .Item-arrow {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      transition: transform 0.6s ease;

      .Icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 18px;
        font-size: 14px !important;
        line-height: 18px !important;
        &:before {
          font-size: 14px !important;
          line-height: 18px !important;
          text-align: center;
        }
      }
    }
  }

  &.is-active {
    .Item-arrow {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .Block--onethird {
    //border-bottom:none;
    border-top: 1px solid;
    margin-top: -1px;

    &:last-child {
      position: relative;
      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background: black;
        position: absolute;
        top: 0;
        right: -1px;
        display: block;
      }

      &:nth-child(3n) {
        &:after {
          display: none;
        }
      }
    }
  }
  .Link--more {
    display: block;

    text-align: center;
    border-bottom: 1px solid;
    padding: 10px;
    cursor: pointer;
    @extend %u-text--smallCaps;
  }
}

.Item--map {
  background: white;
  padding: 0 !important;
  .Item-head {
    padding: 10px;
    margin: 0;
    border-bottom: $base-border solid black;
    * {
      font-size: 12px !important;
      letter-spacing: 0.03em;
      font-family: $base-font-family !important;
      padding: 0;
      margin: 0;
    }
  }
  .Item-media {
    margin: 0;
    padding: 0;
  }
}

.Item--nopadding {
  padding: 0;
  .Image-media {
    background-position: 0% center;
  }
  .Item-head {
    .Content h1 {
      padding: 0 20px;
    }
  }
  .Item-body {
    .Content p {
      padding: 0 20px 20px 20px;
    }
  }
}

.Item--nopadding-cover {
  padding: 0;
  .Image-media {
    background-color: #000;
    background-size: contain;
  }
}

.Item--nopadding-center {
  padding: 0;
  .Image-media {
    background-position: center;
  }
}
