%u-iconfont{
	
	/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	
}

@each $icon,$glyph in $base-icon-glyphs{
  
  %u-iconfont--#{$icon}{
    
     content: "#{$glyph}";
  
    
  }
  
}