$head-height: 120px;
$mobile-head-height: 55px;

/*
	
	html,body
	------------------------
	Root styles

*/

html {
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &.Html--ready {
    cursor: default;
  }

  cursor: wait;
}

body {
  overflow-x: hidden;
  overscroll-behavior: contain;
}

/*

	Meta
	---------------------
	On page content - hidden

*/

.Site-meta {
  display: none !important;
}

/*

	Background
	---------------------
	Background colour for site, while loading too

*/

.Site-bg {
  min-height: 100vh;
  // background: url("/ui/img/EIB_Acme50_Logos/Orange/EIB_Acme50_HalftonePattern_Screen_Square_01.png");
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: auto;
}

/*

	Splash
	---------------------
	Large logo while loading

*/

.Site-splash {
  position: fixed;
  width: 50%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 0;
  //mix-blend-mode:overlay;
  transition: opacity 0.5s ease;
  background-color: #DFF0E5;
  background: url("/ui/img/logo/acme-strap-red.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 600px 232px;
  .Site--ready & {
    opacity: 0;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    background-size: 300px 116px; // maintain ratio
  }
}

/*

	Burger
	---------------------
	Opens the burger menu

*/

.Site-burger {
  position: fixed;
  top: 0;
  right: 0;
  @include responsive("padding", $base-padding);
  z-index: 7;
  cursor: pointer;
  transition: opacity 0.4s ease;
  opacity: 1;

  font-weight: bold;

  &:after {
    content: "Menu";
    display: inline-block;
  }

  @include respond-to("mobile") {
    padding-top: 22px;
    padding-right: $base-mobile-spacing;
  }

  display: block;

  @include respond-to("tablet-up") {
    display: none;
  }

  .Site--introComplete & {
    opacity: 1;
  }
  .Site--menu & {
    &:after {
      content: "Close";
    }
  }
}

/*
	
	.Site-menu
	------------------------
	Burger Menu Slide Out

*/

.Site-menu {
  background: rgba(255, 255, 255, 1);
  position: fixed;
  top: 0;
  min-width: 400px;
  right: 0;
  bottom: 0;
  z-index: 4;
  transition: transform 0.5s ease;
  transform: translate(100%, 0);
  pointer-events: none;
  padding: 40px;
  opacity: 1;
  padding-top: 155px;
  //box-shadow:-1px 0 1px rgba(0,0,0,0.25);
  border-left: 1px solid black;

  @include respond-to("mobile") {
    padding-top: 80px;
    min-width: 65vw;
    padding-left: 30px;
  }

  @include respond-to("mobile-landscape") {
    @include touch-scroll;
    min-width: 50vw;
  }

  @include respond-to("desktop-plus") {
    display: none;
  }

  .Site--menu & {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
}

/*
	
	.Site-menu-contact
	------------------------
	Quick contact in burger nav

*/

.Site-menu-contact {
  position: absolute;
  bottom: 20px;
  left: 40px;

  @include respond-to("mobile") {
    left: 30px;
    bottom: 10px;
  }

  @include respond-to("mobile-landscape") {
    position: relative;
    left: auto;
    bottom: auto;
    padding-top: 50px;
  }

  .Content {
    padding-bottom: 20px;
    a {
      color: black;
    }
  }
}

/*
	
	.Site-menu-close
	------------------------
	Covers the background for click anywhere close of burger nav

*/

.Site-menu-close {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: none;

  .Site--menu & {
    display: block;
  }
}

/*
	
	.Site-backToTop
	------------------------
	Back to top button

*/

.Site-backToTop {
  @extend %u-text--body;
  padding: 40px;
  text-align: right;
  span {
    cursor: pointer;
    transition: border 0.4s ease;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid;
    }
  }
  @include respond-to("mobile") {
    padding: $base-mobile-spacing;
  }

  @include respond-to("desktop") {
    .Site--home & {
      margin-right: (1/6) * 100vw;
    }
  }

  display: none;
}

/*
	
	.Site-logo
	------------------------
	Logo

*/

.Site-logo {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 50%;

  a {
    content: "";
    display: block;
    width: 200px;
    height: 80px;
    @include hide-text();
    background: url("/ui/img/logo/acme-strap-black.svg")
      center center no-repeat;
    background-size: 100% auto;

    @include respond-to("mobile") {
      width: 80px;
      height: 30px;
    }
  }

  a {
    pointer-events: all;
  }
}

/*
	
	.Site-nav
	------------------------
	Desktop nav

*/

.Site-nav {
  position: relative;
  display: inline-block;
  width: 50%;
  vertical-align: bottom;
  text-align: right;

  a {
    pointer-events: all;
  }

  .List {
    text-align: right;
    line-height: 100%;
    li:last-child {
      padding-right: 0;
    }
  }
  @include respond-to(mobile) {
    display: none;
  }

  .Acme-50 {
    display: none;
  }
}

/*
	
	.Site-search
	------------------------
	Search suggestion and input

*/

.Site-search {
  //@include responsive('padding',$base-padding);
  //margin-top:$base-border;

  .Form {
    border: none !important;
  }

  .Site-search-form {
    @extend %u-layout--toolbar;
    padding-right: 0;
    border-bottom: $base-border solid black;
  }
  .Site--filtering & {
    display: none;
  }

  transition: width 0.5s ease;
  @include respond-to("tablet-up") {
    position: fixed;
    z-index: 1;
    top: 120px;
    left: 0;
    width: (1/3) * 100vw;

    .Site--home & {
      width: 50vw;
    }
  }

  @include respond-to("mobile") {
    display: none;
  }
}

/*
	
	.Site-browse
	------------------------
	Open the locations browser

*/

.Site-browse {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  left: 15px;
  box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.2);
  .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    @extend %u-text--body;
    font-weight: bold;
    text-shadow: 1px 1px 0px white, -1px -1px 0px white;

    content: "Browse our spaces \2192 ";
    display: block;
    //color:rgba(0,0,0,0.8);
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-5px, -50%);
    transition: opacity 0.4s ease, transform 0.4s ease;
    pointer-events: none;
    padding-left: 10px;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translate(0%, -50%);
    }
  }

  @include respond-to("portrait") {
    border-radius: 0;
    z-index: 3;
    border-top: 1px solid black;
    box-shadow: none;
    left: 0;
    right: 0;
    bottom: 0;
    height: 55px;
    width: auto;
    text-align: left;
    .Icon {
      left: 20px;
      transform: translate(0%, -50%);
    }

    &:after {
      opacity: 1;
      left: 50px;
      text-shadow: none;
      transform: translate(0px, -50%);
    }
  }
}

/*
	
	.Site-filters
	------------------------
	Search filters at all time

*/

.Site-filters {
  @include responsive("padding", $base-padding);
  padding-bottom: 35px;
  @include respond-to("tablet-up") {
    position: fixed;
    bottom: 0;
    left: 0;
    //width:50vw;
    //border-top:$base-border solid black;
    h3 {
      transition: background 0.2s ease;
    }
    .Site--home & {
      h3 {
        //background:white;
      }
    }
    span,
    h3 {
      display: inline-block;
    }
  }

  @include respond-to("mobile") {
    display: none;
  }

  pointer-events: none;
  a {
    pointer-events: all;
  }
  span {
    display: inline-block;
    padding: 0 5px;
  }
}

.Site-filters-heading {
  display: inline-block;
  padding: 0 5px;
  //border-radius: 15px;
  line-height: 200%;
  transform: translateY(2px);
  //box-shadow:0 0 1px 1px rgba(0,0,0,0.25);
}

/*
	
	.Site-results
	------------------------
	List of matching search items

*/

.Site-results {
  padding-top: $head-height;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  @include respond-to("tablet-up") {
    width: 55.55vw;
    border-right: $base-border solid black;
  }

  @include respond-to("desktop") {
    width: 33vw;
    border-right: $base-border solid black;
  }

  @include respond-to("mobile") {
    padding-top: $mobile-head-height;
    z-index: 4;
  }

  @include respond-to("portrait") {
    z-index: 4;
  }

  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateX(-50px);
  opacity: 0;
  pointer-events: none;

  overflow: hidden;
  .Results {
    position: absolute;
    top: $head-height;
    bottom: 0;
    left: 0;
    right: 0;
    @include respond-to("mobile") {
      top: $mobile-head-height;
    }
  }

  .Item--block {
    background: white;
    border-bottom: $base-border solid black;

    &:hover {
      background: #ddd;
    }
  }

  .Site--filtering & {
    transform: none;
    opacity: 1;
    pointer-events: all;
  }
}

/*
	
	.Site-results-map
	------------------------
	Map of search results

*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.Site-results-map {
  position: fixed;
  top: 0;
  right: 0;
  left: 33vw;
  bottom: 0;
  opacity: 0;
  z-index: 0;
  //mix-blend-mode: luminosity;
  border-left: $base-border solid black;
  margin-left: -$base-border;
  transition: opacity 1.5s ease, left 0.3s ease, right 0.3s ease;
  opacity: 0;
  left: 0;
  right: 50%;
  .MiniMap {
    top: 150px;
    @include respond-to("wide") {
      top: 160px;
    }

    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    opacity: 1;
  }

  left: 0;
  right: 66.666666666%;

  .Site--showMap & {
    opacity: 1;
  }

  .gmnoprint,
  .gm-control-active,
  .gm-style-cc,
  a[rel="noopener"] {
    //display:none;
  }

  .Site--home & {
    right: 50%;
    //bottom:30vh;
    //border-bottom:$base-border solid black;
  }

  .Site--filtering & {
    transform: none;

    pointer-events: all;
    left: 33vw;
    right: 6.5vw;

    .MiniMap {
      top: 100px;
      animation: none;
      opacity: 1;
    }

    .gmnoprint,
    .gm-control-active,
    .gm-style-cc,
    a[rel="noopener"] {
      display: block;
    }
  }

  display: none;

  @include respond-to("desktop") {
    display: block;
  }
}

/*
	
	.Site-detail
	------------------------
	Overlay for detail panel after clicking on a search result

*/

.Site-detail {
  position: fixed;
  top: $head-height;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  z-index: 3;
  background: white;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateX(50px);
  pointer-events: none;
  border-left: $base-border solid black;
  margin-left: -$base-border;

  .Site--details & {
    transform: none;
    opacity: 1;
    pointer-events: all;
  }

  @include respond-to("desktop") {
    z-index: 1;
    right: 6.5vw;
    left: 33vw;
  }

  @include respond-to("mobile") {
    top: $mobile-head-height;
    z-index: 4;
    left: 0;
    right: 0;
  }

  @include respond-to("portrait") {
    z-index: 5;
  }
}

/*
	
	.Site-intro
	------------------------
	Intro overlay

*/

.Site-intro {
  display: none;
}

/*
	
	.Site-head
	------------------------
	Wrapper forlogo and nav

*/

.Site-head {
  border-bottom: $base-border solid black;
  @include responsive("padding", $base-padding);
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  right: 0;
  background: white;

  @include respond-to("mobile") {
    padding-bottom: 10px;
  }
}

/*
	
	.Site-page
	------------------------
	Wrapper for all content

*/

.Site-page {
  margin-left: auto;
  transition: transform 0.5s ease;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background: white;

  @include respond-to("desktop") {
    width: (2/3) * 100vw;
    border-left: $base-border solid black;

    .Site--home & {
      transform: translateX((1/6) * 100vw);
    }

    .Site--filtering & {
      transform: translateX(60vw);
      cursor: pointer;

      .hide-map {
        margin-left: 0;
      }

      &:hover {
        //transition: transform 0.4s ease;
        transform: translateX(55vw);
      }
    }
  }
}

/*
	
	.Site-page-close
	------------------------
	Hidden link to close the map filters when they are open

*/

.Site-page-close {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  .Site--filtering & {
    display: block;
  }
}

/*
	
	.Site-foot
	------------------------
	Footer

*/

.Site-foot {
  //border-top:$base-border solid black;
  @include responsive("padding", $base-padding);

  padding-right: 25vw;
  position: relative;

  padding-left: 20px;

  &.Site-foot--signoff {
    border-top: $base-border solid black;
    padding-bottom: 35px;

    @include respond-to("portrait") {
      padding-bottom: 80px;
    }
  }
}

/*
	
	.Site-foot-newsletter
	------------------------
	Newsletter in footer

*/

.Site-foot-newsletter {
  @include responsive("padding-bottom", $base-padding);
}

/*
	
	.Site-foot-contact
	------------------------
	Contact detail grid in footer

*/

.Site-foot-contact {
  padding-bottom: 50px;
  max-width: 500px;
}

/*
	
	.Site-foot-nav
	------------------------
	Footer nav

*/

.Site-foot-nav {
}

/*
	
	.Site-foot-social
	------------------------
	Social icons in footer

*/

.Site-foot-social {
}

/*
	
	.Site-foot-logos
	------------------------
	Sponsor logos

*/

.Site-foot-logos {
  padding-top: 20px;

  @include respond-to("tablet-up") {
    padding-top: 0;
    position: absolute;
    right: 0;
    bottom: 10px;
    text-align: right;
  }

  //border-bottom:$base-border solid black;

  a {
    display: block;
  }
  img {
    height: 70px;
    width: auto;
  }

  @include respond-to("desktop") {
    .Site--home & {
      right: (1/6) * 100vw;
    }
  }
}

/*
	
	.Site-foot-credit
	------------------------
	Design by credit

*/

.Site-foot-credit {
  @include responsive("padding", $base-padding);
  padding-left: 0 !important;
  padding-bottom: 0;

  @include respond-to("desktop-plus") {
    position: absolute;
    top: 0;
    right: 0;
    padding-bottom: 35px;
  }

  @include respond-to("desktop") {
    .Site--home & {
      right: (1/6) * 100vw;
    }
  }
}
