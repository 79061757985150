.Blocks--debug {
  .Block {
    margin-top: 5px;
    border-top: 1px solid $base-ui-color;
    border-right: none;
    border-left: none;

    &:before {
      content: attr(data-type) " \02193";
      display: inline-block;
      background: $base-ui-color;
      color: $base-background;
      padding: 5px;

      margin-bottom: 5px;
    }
  }

  .Image {
    &:before {
      content: attr(data-width) "px";
      display: inline-block;
      background: $base-background;
      padding: 5px;
      font-family: monospace;
      font-size: 10px;
      margin-bottom: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
    }
  }
}

.Blocks {
  margin-left: -$base-border;
  margin-right: auto;
}

.Block {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: auto;
  border-left: $base-border solid black;
  border-bottom: $base-border solid black;
  position: relative;
  &.Block--flush {
    border-color: transparent;
    padding-bottom: 0;
    .Block-content {
      min-height: 0 !important;
      padding-bottom: 0;
    }
  }
  &.Block--basic,
  .Template--basic & {
    border-color: transparent;
    padding-bottom: 0;
    .Block-content {
      min-height: 0 !important;
    }
  }
}

/*

Embed

*/

.Block--embed {
  .Block-iframe {
    position: relative;
    margin: 0 auto;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
    }
  }

  &.Block--audio {
    .Block-iframe {
      &:after {
        height: 60px;
      }
    }
  }

  &.Block--video {
    .Block-iframe {
      &:after {
        padding-top: 56.25%; //16:9
      }
    }
  }
}

.Block--item {
  transition: background 0.4s ease;

  &.Block--default.Block--noImage {
    .Item {
      .Item-head {
        min-height: 18vh;
        .Content {
          h1,
          h2 {
            @extend %u-text--intro;
            font-weight: normal;
          }
        }
      }
    }
  }

  .Image {
    background: $pale-blue-accent;

//    background: #f79b2e;
  }

  &:nth-child(2n + 0) {
    .Image {
      background: $pale-green-accent;

      //background: #f79b2e;
    }
  }
  &:nth-child(3n + 0) {
    .Image {
      background: $pale-pink-accent;

      //background: #f79b2e;
    }
  }
  &:nth-child(4n + 0) {
    .Image {
      background: $pale-purple-accent;
      //background: #f79b2e;
    }
  }
  &:nth-child(5n + 0) {
    .Image {
      background: $pale-brown-accent;

      //background: #f79b2e;
    }
  }
  &:nth-child(6n + 0) {
    .Image {
      background: $pale-blue-accent;

      //background: #f79b2e;
    }
  }
  &:nth-child(7n + 0) {
    .Image {
      background: $pale-green-accent;

      //background: #f79b2e;
    }
  }

  .Html--no-touch & {
    &.Block--hasLink:hover {
      /*.Image {
        &:after{
			position:absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			content:'';
			display:block;
			//background:url(/ui/img/halftone.png) center center no-repeat;
			//background-size:40vw auto;
			mix-blend-mode:overlay;
			pointer-events:none;
			z-index:2;
		}
      }*/

      &.Block--onequarter {
        .Image {
          &:after {
            background-size: 30vw auto;
            background-position: 20% center;
          }
        }
      }

      &.Block--full {
        .Image {
          &:after {
            background-size: cover;
          }
        }
      }

      background-color: $pale-blue;

      .Item-foot {
        color: $pale-blue-accent;
      }

      //.Image {
        //background:$pale-blue-accent;
      //}
      .Image {
        filter: grayscale(1);
        mix-blend-mode: luminosity; //soft-light if using item-media
      }

      &:nth-child(2n + 0) {
        background-color: $pale-green;

       // .Image {
          //background:$pale-green-accent;
       // }
        .Item-foot {
          color: $pale-green-accent;
        }
      }
      &:nth-child(3n + 0) {
        background-color: $pale-pink;
       // .Image {
          //background:$pale-pink-accent;
       // }
        .Item-foot {
          color: $pale-pink-accent;
        }
      }
      &:nth-child(4n + 0) {
        background-color: $pale-purple;
       // .Image {
          //background:$pale-purple-accent;
       // }
        .Item-foot {
          color: $pale-purple-accent;
        }
      }
      &:nth-child(5n + 0) {
        background-color: $pale-brown;
        //.Image {
          //background:$pale-brown-accent;
       // }
        .Item-foot {
          color: $pale-brown-accent;
        }
      }
      &:nth-child(6n + 0) {
        background-color: $pale-blue;
       // .Image {
          //background:$pale-blue-accent;
       // }
        .Item-foot {
          color: $pale-blue-accent;
        }
      }
      &:nth-child(7n + 0) {
        background-color: $pale-green;
        //.Image {
          //background:$pale-green-accent;
        //}
        .Item-foot {
          color: $pale-green-accent;
        }
      }
    }
  } // End Html-noTouch

  &.Block--callToAction {
    padding: 0;
    height: 20vh; // Overwritten by JS
    //min-height:300px;
    position: relative;
    .Block-content {
      height: 100%;
    }

    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: url(/ui/img/halftone.png) center center no-repeat;
      background-size: 100% auto;
      mix-blend-mode: overlay;
      opacity: 0;
      z-index: 0;
    }
    .Item {
      position: relative;
      height: 100%;
      @extend %u-valign-children;
      z-index: 1;
    }
    .Item-foot {
      text-align: center;
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      background-size: 100% auto;
      &:nth-child(2n + 0) {
        * {
          color: $pale-green-accent;
        }
      }
      &:nth-child(3n + 0) {
        * {
          color: $pale-pink-accent;
        }
      }
      &:nth-child(4n + 0) {
        * {
          color: $pale-purple-accent;
        }
      }
      &:nth-child(5n + 0) {
        * {
          color: $pale-brown-accent;
        }
      }
      &:nth-child(6n + 0) {
        * {
          color: $pale-blue-accent;
        }
      }
      &:nth-child(7n + 0) {
        * {
          color: $pale-green-accent;
        }
      }
    }
    .Item {
      .Item-head {
        margin: 0;
        .Content {
          h1,
          h2 {
            @extend %u-text--intro;
            font-weight: normal;
          }

          text-align: center;
        }
        .Content h1 {
          font-weight: bold;
        }
      }
    }
  }
}

.Block--anchor {
  height: 0;
  border: none;
  display: block;
  width: 100%;
}

.Block--details {
  .Content {
    width: 50%;
  }
}
.Block--text {
  .Block-content {
    @include responsive("padding", $base-padding);

    padding-bottom: 80px;
    @include respond-to("mobile") {
      padding-bottom: 40px;
    }
    .Content {
      @include respond-to("desktop") {
        width: 95%;
        .Site--home & {
          width: 90%;
        }
        //max-width:800px;
      }
    }
  }

  .Grid-item--sort {
    @include responsive("padding-top", $base-padding);
  }

  .Template--blocks & {
    &.Block--full {
      .Block-content {
        //padding-top:40px;
        min-height: 37vh;
        @include respond-to("portrait") {
          min-height: 33vh;
        }
      }
    }
  }
}

.Block--carousel.Block--full {
  height: auto !important;
}
.Block--carousel.Block--half {
  height: 55vh;
  position: relative;
  overflow: hidden;
  .Block-content {
    padding: 0;
  }

  .Image {
    height: 55vh !important;
    padding: 0 !important;
  }
}

.Block--items {
  .Block-content {
    @include responsive("padding", $base-padding);

    padding-bottom: 80px;
    @include respond-to("mobile") {
      padding-bottom: 40px;
    }
  }

  .Block-items {
    @include responsive("margin", $base-padding);
    position: relative;
  }

  .Block-item-grid {
    @extend %u-grid;
  }

  .Block-item {
    @extend %u-gridItem;
    vertical-align: top;
    width: 100%;

    &.Block-item--onehalf {
      width: (1/2) * 100%;
    }
    &.Block-item--onethird {
      width: (1/3) * 100%;
      @include respond-to("mobile") {
        width: 50%;
      }
    }
    &.Block-item--onequarter {
      width: (1/4) * 100%;

      @include respond-to("mobile") {
        width: 50%;
      }
    }
  }
}

.Block--definitionList {
  dl > li {
    border-bottom: $base-border solid black;
    padding-bottom: 80px;
    min-height: 15vh;
    &:last-child {
      border-bottom: none;
    }

    .Content {
      @include respond-to("desktop") {
        //width:70%;
        max-width: 600px;
      }
    }
  }
}

.Block--full {
  display: block;
  width: 100%;

  .Block-iframe {
    &:after {
      padding-top: 56.25%; //16:9
    }
  }
}

.Block--half {
  width: 50%;

  @include respond-to("portrait") {
    .Content {
      max-width: 500px;
    }
  }

  @include respond-to("mobile") {
    width: 100%;
    display: block;
  }

  .Results-body & {
    width: auto;
    display: block;
    @include respond-to("desktop") {
      width: 50%;
      display: inline-block;
    }
  }

  .Block-iframe {
    &:after {
      padding-top: 56.25%; //16:9
    }
  }
}

.Block--onethird {
  width: (1/3) * 100%;

  @include respond-to("mobile") {
    width: 100%;
    display: block;
    /*width:(1/1) * 100%;
      		
      		

      		&.Block--orientation-portrait{
      			width:50%;
      		}*/
  }
}

.Block--orientation-portrait {
  @include respond-to("mobile") {
    /*.Item.Item--block .Image{
			padding-top:150%!important;
		}*/
  }
}

.Block--twothirds {
  width: (2/3) * 100%;

  @include respond-to("mobile") {
    width: 100%;

    & + .Block--onethird {
      width: 100%;
    }
  }

  .Block-iframe {
    &:after {
      padding-top: 56.25%; //16:9
    }
  }
}

.Block--onequarter {
  width: (1/4) * 100%;

  @include respond-to("mobile") {
    width: 50%;
  }
}

.Block--map {
  height: 45vh; //Will be overridden by JS
  overflow: hidden;

  .Block-content {
    @extend %u-box--fluid;
    height: 100%;
    min-height: 45vh;

    .MiniMap {
      z-index: 1;
      position: absolute;
    }

    & > * {
      @extend %u-box--fluid;
    }
  }
}
