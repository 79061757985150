.Image {
 // background: #f59d2b;
}

.Image-caption {
  padding: 10px 0 20px 0;
}
.Image-media {
  opacity: 0;
  transition: opacity 0.6s 0s ease;
  z-index: 1;
}

.Image {
  /*&:after{
		content:'';
		display:block;
		position:absolute;
		width:10px;
		height:10px;
		background:black;
		top:10px;
		left:10px;
		border-radius:50%;
		animation:pulse 1s infinite;
		z-index:0;
	}*/
}

.Image--loaded {
  background: transparent !important;
}
.Image-media[style],
.Image-media[src] {
  //animation:fadeIn 1s 0.1s forwards;
  backface-visibility: hidden;
  opacity: 1;
}
