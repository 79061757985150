.Map-marker{

	width:20px;
	height:20px;
	background:transparent;
	border-radius:50%;
	overflow:visible!important;

	
}

.Map-card{
	position:absolute;
	
	width:150%;
	height:150%;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);

	&.is-active{
		.Map-card-item{
			opacity:1;
		}
		.Map-card-dot{
			background:black!important;
		}
	}
}

.Map-card-item{
	width:150px;
	border:$base-border solid black;
	opacity:0;
	transition:opacity 0.4s ease;
	pointer-events:none;
	position:absolute;
	bottom:100%;
	left:50%;
	transform:translate(-50%,0);

	
}

.Map-card-dot{
	position:absolute;
	background:black;
	width:20px;
	height:20px;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	border-radius:50%;
	pointer-events:none;
	border:2px solid rgba(0,0,0,0.3);
	//border:1px solid black;
}