@font-face {
    font-family: "Founders Grotesk";
    src: url("./FoundersGroteskWeb-Medium.woff2") format("woff2"),
        url("./FoundersGroteskWeb-Medium.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Founders Grotesk";
    src: url("./FoundersGroteskWeb-Regular.woff2") format("woff2"),
        url("./FoundersGroteskWeb-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
