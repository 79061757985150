$button-size:40px;
.CarouselGallery{
  position:relative;

  .slick-dots{
    display:none!important;
  }

  .slider{
    position:relative;
    z-index:1;
  }

  .slick-arrow{
    width:$button-size;
    height:$button-size;
    border:$base-border solid black;
    border-bottom:none;
    border-right:none;
    background:white;
    position:absolute;
    bottom:0;
    right:0;
    z-index:2;
    outline:none!important;
    @include hide-text();

     &:after{
        @extend %u-iconfont;
       
        position:absolute;
        left:50%;
        top:50%;
        display:block;
        text-indent:0;
        transform: translate(-50%,-50%);
        width:100%;
        text-align:center;
      }
  }

  .slick-next{
    &:after{
       @extend %u-iconfont--right;
    }
  }
  .slick-prev{
    right:$button-size;
     &:after{
      
        @extend %u-iconfont--left;
        
      }
  }
}