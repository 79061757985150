$results-head-height:4vw;
$tablet-height: 55px;
$mobile-height:12vw;

.Results{
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;
	
	.Results-head{
		position:absolute;
		top:0;
		left:0;
		border-bottom:$base-border solid black;
		line-height:$tablet-height;
		height:$tablet-height;
		width:100%;
		padding-left:$tablet-height * 1.2;
		@extend %u-layout--shadowed;
		z-index:2;
		background:white;

		@include respond-to('wide'){
      		line-height:$results-head-height;
			height:$results-head-height;
			padding-left:$results-head-height * 1.2;
    	}
		@include respond-to('mobile'){
      		line-height:$mobile-height;
			height:$mobile-height;
			padding-left:$mobile-height * 1.2;
    	}
	}
	.Results-back,
	.Results-close,
	.Results-dropdown-arrow{
		position:absolute;
		width:$tablet-height;
		height:$tablet-height;
		border:$base-border solid black;
		border-left:none;
		border-top:none;
		top:0;
		left:0;

		@include respond-to('wide'){
      		width:$results-head-height;
			height:$results-head-height;
    	}
		@include respond-to('mobile'){
      		width:$mobile-height;
			height:$mobile-height;
    	}

		a,span{
			display:block;
			top:0;
			left:0;
			right:0;
			bottom:0;
			text-align:center;
			cursor:pointer;
			.Icon{
				width:$tablet-height;
				height:$tablet-height;
				&:before{
					font-size:$tablet-height * 0.3;
					height:$tablet-height;
					line-height:$tablet-height;
				}

				@include respond-to('wide'){
		      		width:$results-head-height;
					height:$results-head-height;

					&:before{
						font-size:$results-head-height * 0.3;
						height:$results-head-height;
						line-height:$results-head-height;
					}
		    	}

				@include respond-to('mobile'){
		      		width:$mobile-height;
					height:$mobile-height;

					&:before{
						font-size:$mobile-height * 0.3;
						height:$mobile-height;
						line-height:$mobile-height;
					}
		    	}
			}
		}
	}

	.Results-dropdown{
		cursor:pointer;
		&:hover{
			.Results-dropdown-arrow{
				background:rgba(255,255,255,0.5);
			}
		}
		.Results-dropdown-arrow{
			left:auto;
			right:0;
			border-right:none;
			border-left:$base-border solid black;
		}
		.Results-dropdown-body{
			display:none;
			border:$base-border solid black;
			border-right:none;
			border-left:none;
			background:white;
			position:absolute;
			top:100%;
			left:0;
			right:0;
			line-height:100%;
			box-shadow:0 1px 0 0 rgba(0,0,0,0.25);

			li{
				border-bottom:$base-border solid black;
				@extend %u-text--body;

				&:last-child{
					border:none;
				}
			}
			a{
				display:block;
				padding:20px;

				&:hover{
					background:rgba(0,0,0,0.15);
				}

				&.is-active{
					pointer-events:none;
					opacity:0.5;
				}
			}
		}

		&.is-active{
			.Results-dropdown-arrow{
				background:white;
			}
			.Results-dropdown-body{
				display:block;
			}
		}
	}
	
	.Results-body{
		padding-top:$tablet-height;
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		overflow-y:auto;
		z-index:1;

		@include respond-to('wide'){
      		padding-top:$results-head-height;
    	}

		@include respond-to('mobile'){
      		padding-top:$mobile-height;
    	}

		.Item{
			position:relative;
			z-index:1;
		}
		.Html--touch &{
			@include touch-scroll();
		}
	}
}