/*
  
  .Template
  ------------------------
  Layout an ENTRY from CMS

*/

.Template {
  padding-top: 120px; //Header

  @include respond-to("mobile") {
    padding-top: 55px;
  }

  //padding-bottom:80px;

  // Half size paragraphs on wide screens
  @include respond-to("wide") {
    .Block--text.Block--full {
      .Content {
        h3,
        h4,
        h5,
        p,
        ul {
          width: 50%;
        }
      }
    }
  }
}
.Template-head {
  display: none;
}

/*
  
  .Template--homepage
  ------------------------
  Front page

*/

.Template--homepage {
  @include respond-to("desktop") {
    padding-right: (1/6) * 100vw;
  }
}

.Template-breadcrumbs {
  @extend %u-layout--toolbar;
  border-bottom: $base-border solid black;

  .List {
    max-width: 90vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.hide-map {
  @media screen and (min-width: 1024px) {
    margin-left: -33vw;
    background-color: white;
  }
}
