.List--nav{


  li{

  	display:inline-block;
    padding-right:25px;
    //text-transform:uppercase;
    //letter-spacing:0.02em;
     @extend %u-text--body;
     font-weight: bold;

    &:last-child{

        //padding-left:20px;
        ul{
          display:none!important;
        }

    }
    
  }

  a{
    opacity:1;
    cursor:pointer;
    border-bottom:1px solid transparent;
    &.is-active{
      font-weight:bold;
      opacity:1;
      //border-bottom:1px solid;
    }
  }


}

.List--definition{

  li{
 

    & > *{
      display:inline-block;
      vertical-align:top;
       @include responsive('padding',$base-padding);
    }

    & > dt{
      width:40%;
      @include respond-to('mobile'){
        width:100%;
      }
    }

    & > dd{
      width:55%;

      @include respond-to('mobile'){
        width:100%;
      }
    }
    @include responsive('padding-bottom',$base-padding);
  }
}

.List--filters{


  li{

     @extend %u-text--intro;

    
    
  }

  a{
    border-bottom:1px solid transparent;
    &:hover{
      border-bottom:1px solid;
    }
  }


}

.List--menu{

  *{

    @extend %u-text--burger;

  }

  
  li{

    &:last-child{

        //padding-left:20px;
        ul{
          display:none!important;
        }

    }
    
  }

   .Site--home &{
         
    & > li:first-child{
      ul{

        display:block;

      }
    }

  }

  a{
    opacity:1;
    cursor:pointer;
  }

  ul{
    padding-left: 1em;
    a{
      color:#999;
      color:black;
      &.is-inactive{
        color:black;
      }
      border-bottom:1px solid transparent;
      transition:border 0.4s ease;
      padding-bottom:2px;
    }
    a.is-active{

      border-bottom:1px solid #999;
      border-color:black;
    }
    li:last-child{
      padding-right:0;
    }
  }

  a{
    color:black;
    &.is-inactive{
      color:#999;
      .Site--home &{
        color:black;
      }
    }


  }



  ul{
        display:none;
        padding-bottom:1em;
        padding-left: 1em;

       
       
      }

      a.is-active + ul{
        display:block;
      }



}

.List--sub{
  
  li{

    cursor:pointer;
    display:inline-block;
    padding-right:20px;
    transition:color 0.4s ease;

    .is-active{
      color:#999;
    }
  }
}

.List--icons{
  li{

      display:inline-block;
     
      padding-right:10px;

      a{
        border:none;
      }
  }
}

.List--inline{
  li{

      display:inline-block;
     
      padding-right:10px;
      margin:0!important;

  }
}

.List--definition{
  li{
    list-style:none;
    padding-bottom:10px;

    dt,dd{
      
    }

    dt{
      //color:$grey;
    
    }
  }
}

.List--breadcrumbs{
  line-height:100%;
  li{
     @extend %u-text--small;
    line-height:100%;
    text-transform:capitalize;
    display:inline-block;
    &:after{
      content:'/';
      padding:0 5px;
    }

    &:last-child{
      font-weight:bold;
      pointer-events:none;
      &:after{
        display:none;
      }
    }
  }
}


