@keyframes load{

	0%{

		opacity:1;
		width:0%;
		

	}

	90%{

		//opacity:0;
		//height:0;
		//background:#EEE;
		width:100%;

	}
	

	100%{

		//opacity:0;
		//height:0;
		//background:$loadingBg;
		width:100%;

	}

}

.Loader--dot{
	@include hide-text;
}

.Loader{
	@include hide-text;
	position:fixed;
	bottom:0;
	left:0;
	background:#000;
	width:0;
	height:5px;
	animation: load 1s forwards;
	display:block;
	z-index:9;

	will-change:background;
	transform:translate3d(0,0,0);

	

	.Html--ready &{
		animation:none;
		width:100%;
		height:0;
		transition:height 0.4s ease;
	}

}