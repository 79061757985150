%u-text--hero{

	font-family:$base-font-family-alt;
	@include font-size(50px);

}

%u-text--logo{

	font-family:$base-font-family-alt;
	font-size:60px;

	@include respond-to('mobile'){
		font-size:25px;
	}

}

%u-text--body{

	@include font-size(15px);

	

	@include respond-to('wide'){
		@include font-size(17px);
	}


	@include respond-to('mobile'){
		@include font-size(13px);
	}

	strong{
		font-weight:bold;
	}

}


%u-text--small{


	@include font-size(13px);

	@include respond-to('desktop'){
		font-size:1vw;
	}

	@include respond-to('wide'){
		@include font-size(14px);
	}

	@include respond-to('mobile'){
		@include font-size(11px);
	}

	letter-spacing:0em;

}

%u-text--smallCaps{


	@include font-size(14px);

	@include respond-to('mobile'){
		@include font-size(12px);
	}


	letter-spacing:0.04em;

	text-transform:uppercase;

}

%u-text--intro{

	font-size:4vw;

	@include respond-to('desktop'){
		font-size:2.3vw;
	}

	@include respond-to('mobile'){
		font-size:6.5vw;
	}

}

%u-text--burger{

	@extend %u-text--intro;

}